.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.car-park-display {
  position: relative;
  width: 100%;
}

.car-park-display img {
  width: 100%;
}

.space-marker {
  position: absolute;
  background-image: url('/images/car-east.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 16%;
  height: 50%;
}

.space-marker img {
  width: 20%;
  top: 30%;
  position: absolute;
  left: 25%;
}

.tooltip-img {
  height: 1em;
  padding-right: 0.5em;
}

.space-1 {
  top: 4%;
  left:85%;
}

.space-2 {
  top: 4%;
  left: 70%;
}

.space-3 {
  top: 4%;
  left: 56%;
}

.space-4 {
  top: 4%;
  left: 29%;
}

